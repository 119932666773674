.toggle {
  display: flex;
  flex-direction: column;
  align-items: start;

  .disabled {
    cursor: not-allowed;
    opacity: 0.5;
  }

  p {
    margin: 0 !important;
  }

  &.error {
    display: flex;
    align-items: end;
    label {
      border: 1px solid var(--error600);
    }
  }

  .error {
    padding-top: 8px;
    color: var(--error600);
    font-size: 12px;
    line-height: 1;
    width: 80%;
  }
}
