.searchBox {
  display: flex;
  align-items: center;
  justify-content: space-between;

  .searchIcon {
    position: absolute;
    left: 12px;
    top: 16px;
    color: var(--neutral700);
  }

  input {
    padding-left: 44px !important;

    &:focus {
      margin: 0 4px !important;
    }
  }

  button {
    background: none;
    color: var(--neutral800);
    position: absolute;
    right: 20px;
    top: 12px;
    min-width: 0;
    padding: 0;

    &:hover,
    &:active {
      background: none;
    }
  }
}
