.text-bold {
  font-weight: 700;
}

.text-italic {
  font-style: italic;
}

.text-underline {
  text-decoration: underline;
}

.text-strikethrough {
  text-decoration: line-through;
}

.text-underline-strikethrough {
  text-decoration: underline line-through;
}

.ol1 {
  list-style-position: inside;
}

.ol2 {
  list-style-position: inside;
  list-style-type: upper-alpha;
}

.ol3 {
  list-style-type: lower-alpha;
  list-style-position: inside;
}

.ol4 {
  list-style-type: upper-roman;
  list-style-position: inside;
}

.ol5 {
  list-style-type: lower-roman;
  list-style-position: inside;
}

.ul {
  list-style-position: inside;
}

.link {
  text-decoration: none;

  &:hover {
    text-decoration: underline;
    cursor: pointer;
  }
}
