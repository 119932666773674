.dropdown-menu {
  bottom: unset !important;
}

.input {
  min-width: 0 !important;
  min-height: 38px !important;
}

.input-wrapper > .input-container {
  overflow-x: hidden !important;
}

.datepicker {
  text-transform: none !important;
}

.calendar-day,
.calendar-day_today {
  &:not(.active, .active_first, .active_last, .active_all) {
    background-color: transparent !important;
  }
  .active-first,
  .active-last {
    background-color: var(--neutral100) !important;
  }
}

.calendar-day_selected,
.calendar-day_today_selected {
  &:hover {
    color: var(--neutral0) !important;
    background-color: var(--secondary400) !important;
  }
}

.calendar-day_selected:disabled {
  color: var(--neutral800) !important;
  background-color: var(--secondary100) !important;
}

input:disabled {
  background: var(--neutral200) !important;
  border: 1px solid var(--accent700) !important;
}

.calendar-month-days {
  button {
    margin-left: 0 !important;
  }
}
