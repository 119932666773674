.select {
  min-width: 180px;
  label {
    padding: 4px 0px;

    .helperMessage {
      font-size: 12px;
      color: var(--neutral800);
    }
  }

  .dropdownIndicator {
    margin-right: 8px !important;
  }

  .dismissContainer {
    display: flex;
    justify-content: space-between;
    align-items: center;
    height: 24px;

    button {
      position: absolute;
      right: 0;
      top: -2px;
      width: 24px;
      height: 24px;
    }
  }

  .error {
    padding-top: 8px;
    color: var(--error600);
    font-size: 12px !important;
  }
}
