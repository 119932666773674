.releaseSchedule {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 4px 12px;
  height: 40px;

  &.success {
    background: var(--success400);
    color: var(--neutral0) !important;
  }

  &.error {
    background: var(--error400);
    color: var(--neutral0) !important;
  }

  &.info {
    background: var(--neutral200);
  }

  &.warning {
    background: var(--tertiary300);
    color: var(--neutral0) !important;
  }

  .releaseScheduleText {
    font-size: 12px;
    margin-left: 12px;
  }
}
