.input {
  label {
    margin-top: 4px;
    input {
      margin-top: 4px;
    }
  }

  .error {
    padding-top: 8px;
    color: var(--error600);
    font-size: 12px !important;
  }
}
