.toast {
  box-sizing: border-box;
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 0px 16px 0px 0px;
  gap: 16px;
  position: relative;
  width: 392px;
  height: auto;
  background: var(--neutral0);
  border: 1px solid var(--neutral200);
  border-radius: 8px;

  .toastAccent {
    width: 8px;
    flex: none;
    order: 0;
    align-self: stretch;
    flex-grow: 0;
    border-radius: 8px 0px 0px 8px;

    &.success {
      background: var(--success400);
    }

    &.error {
      background: var(--error400);
    }

    &.info {
      background: var(--secondary300);
    }

    &.warning {
      background: var(--alert400);
    }
  }

  .toastIcon {
    display: flex;
    align-items: baseline;
    height: 64%;
  }

  .toastContainer {
    display: block;
    padding-top: 16px;
    padding-bottom: 16px;

    .titleContainer {
      display: flex;
      justify-content: space-between;
      align-items: flex-start;

      .title {
        display: flex;
        align-items: center;
        margin: 4px 0px;
        font-family: 'Roboto';
        font-weight: 500;
        font-size: calc(var(--unit) * 1.75);
        line-height: 1.25;
        color: var(--neutral800);
        max-width: 224px;

        svg {
          margin-right: 8px;
        }
      }

      .timeStamp {
        font-weight: 400;
        font-size: calc(var(--unit) * 1.5);
        line-height: calc(var(--unit) * 2);
        position: absolute;
        right: 40px;
        margin-left: auto;
        top: 24px;
      }
    }

    .subtitleContainer {
      .subtitle {
        font-family: 'Roboto';
        font-weight: 400;
        font-size: calc(var(--unit) * 1.75);
        line-height: 1.25;
        letter-spacing: 0.004em;
        color: var(--neutral700);
      }
    }

    .linkContainer {
      margin-top: 8px;
      & > a {
        font-size: calc(var(--unit) * 1.5);
      }
    }
  }

  .dismissContainer {
    .dismissButton {
      transform: scale(0.8);
      position: absolute;
      right: 4px;
      top: 16px;
    }
  }
}
