.sideBar {
  border-right: 1px solid var(--neutral200);
  box-shadow: 4px 8px 40px rgba(28, 48, 75, 0.08);

  &.open {
    min-width: 236px !important;
  }

  z-index: 100000;
  button {
    span {
      font-family: 'Material Icons' !important;
    }
  }
}
