.securityGroupsSelector {
  display: flex;

  & > div {
    margin-right: 12px;
  }

  .select {
    min-width: 180px;
    label {
      padding: 4px 0px;
    }

    .dismissContainer {
      display: flex;
      justify-content: space-between;
      align-items: center;

      button {
        position: absolute;
        right: 0;
        top: -2px;
        width: 24px;
        height: 24px;
      }
    }
  }

  &.overflowLayout {
    flex-wrap: wrap;
    gap: 12px;

    & > div {
      margin-right: 0;
      width: 49%;
    }
  }

  &.fullWidth {
    & > div {
      width: 100%;
    }
  }
}
