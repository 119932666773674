.radio {
  .radioGroup {
    display: flex;

    &.horizontal {
      display: block;
    }

    .radioContainer {
      margin: 12px 12px 12px 0px;

      label {
        width: auto;
        display: flex;
        align-items: center;

        input:disabled {
          &::after {
            background-color: var(--neutral800);
          }
        }
      }
      label.disabled {
        cursor: default;
      }
    }
  }
}

.error {
  font-size: 12px;
  color: var(--error600);

  input {
    border-color: var(--error600) !important;
  }
}
