.richTextEditor {
  
  &.disabled {
    & > div {
      background: var(--neutral200);
      color: var(--neutral600);
    }
  }

  .outline {
    border: 1px solid var(--neutral600);
    background: var(--neutral0);
    border-radius: 8px;
    margin-top: 8px;
    padding: 0;

    &.error {
      border: 1px solid var(--error600);
      padding-top: 0;
      color: var(--neutral800);
    }

    .editor {
      outline: none;
      padding: 16px;

      ol,
      ul {
        li {
          list-style: inherit !important;
        }
      }
    }
  }

  .toolbar {
    display: flex;
    align-items: center;
  }

  .controls {
    display: flex;
    padding: 4px;
    background: var(--neutral100);
    border-top-left-radius: 8px;
    border-top-right-radius: 8px;

    .separator {
      border-right: 2px solid var(--neutral600);
      margin-left: 8px;
      margin-top: 14px;
      margin-bottom: 6px;
      margin-right: 12px;
    }

    & > button {
      margin-right: 4px;

      &.selected {
        background: var(--neutral100);
        border: 2px solid var(--secondary400);
      }
    }
  }

  .hyperlinkPanel {
    margin: 0;
    padding: 8px 16px;

    .buttonBar {
      display: flex;
      justify-content: end;
      margin-top: 8px;

      button {
        margin-left: 8px;
      }
    }
  }

  .emojisPanel {
    margin: 0;

    .emojis {
      display: flex;
      flex-wrap: wrap;
      overflow-y: scroll;
      width: 480px;
      height: 228px;

      .emojiButton {
        background-color: transparent;
        min-width: 0;
        padding: 20px !important;
        font-size: 32px;
      }
    }

    &.error {
      & > label {
        color: var(--neutral800);
      }
      & > div {
        & > div {
          border: 1px solid var(--error600);
          color: var(--neutral800);
        }
      }
    }

    &.disabled {
      & > div {
        background: var(--neutral100);
        color: var(--neutral600);
      }
    }
  }

  .link {
    * {
      text-transform: none;
    }
  }

  .error {
    padding-top: 8px;
    color: var(--error600);
    font-size: 12px;
  }

  .thumbnail {
    width: fit-content;
    padding: 0 !important;
    box-shadow: 4px 8px 40px rgba(28, 48, 75, 0.08);
    border-radius: 8px;
    cursor: pointer;

    video {
      border-top-left-radius: 8px;
      border-top-right-radius: 8px;
    }

    .controls {
      border-bottom-left-radius: 8px;
      border-bottom-right-radius: 8px;
      background: var(--neutral0);
      padding: 12px;
      display: flex;
      align-items: center;
    }
  }

  .video {
    border-radius: 8px;

    &.hidden {
      visibility: hidden;
      width: 0;
      height: 0;
    }
  }
}
