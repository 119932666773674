.table {
  a {
    margin-top: 0;
  }

  input[type='checkbox'] {
    &:disabled {
      border: 1px solid var(--neutral500) !important;
      background-color: var(--neutral0) !important;
    }

    &:indeterminate {
      background-color: var(--secondary400);
      border: 1px solid var(--neutral0);

      &::after {
        content: '-';
        font-size: 28px;
        position: relative;
        color: var(--neutral0);
        top: -10px;
        left: 3px;
      }
    }
  }

  .selected,
  .selectedactive {
    border-spacing: 2px;
    background: var(--secondary100);
  }

  .chipRowLabel {
    padding-left: 8px;
  }

  .chipRow {
    display: flex;
    padding: 8px 0 16px 0;

    .rangeValue {
      display: flex;
      padding: 0;
      div {
        margin: 0 4px;
        padding: 0;
      }
    }

    div {
      padding: 0 8px;

      button {
        margin: 0;
        b {
          padding-left: 4px;
        }
      }
    }
  }

  .header {
    &.sortableColumn {
      cursor: pointer;

      &:hover {
        background: var(--secondary100);
        border-radius: 5px;
      }
    }

    .sortHeader {
      display: flex;
      justify-content: space-between;
      align-items: center;
      width: 100%;
      gap: 4px;

      .sortHeaderText {
        display: flex;
        align-items: center;

        svg {
          padding-left: 8px;
        }
      }
      .dropdownMenu {
        width: auto;
        padding: 8px 12px;
        min-width: 280px;
        position: absolute;
        transform: translateX(-100px);
        box-shadow: 0px calc(var(--unit) / 2) calc(2 * var(--unit))
          rgba(72, 48, 75, 0.1);

        .filterActions {
          display: flex;
          gap: var(--unit);
          justify-content: space-between;
          padding: 12px 0;

          button {
            min-width: 120px;
          }
        }

        input[type='text'] {
          min-width: 120px;
        }
        .range {
          display: flex;
          justify-content: space-between;
          & > label {
            width: 120px;

            input {
              display: block;
              width: 120px;
              height: 40px;
              border-radius: 8px;
              border: 1px solid var(--neutral700);
              padding: 8px 16px;
              margin-top: 4px;

              &:focus,
              &:active {
                border: 1px solid var(--secondary400);
                outline: none;
              }
            }
          }
        }

        .multiChoice {
          display: flex;
          align-items: center;
          padding: 8px 4px;
          cursor: pointer;

          input {
            margin-right: 8px !important;
            transform: translateY(2px);
          }
        }
      }

      .filterButton {
        margin-right: 4px;
      }

      user-select: none;
    }
  }
}
